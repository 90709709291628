define('ember-uploader/core', ['exports', 'ember-uploader/version'], function (exports, _version) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * @module ember-uploader
   */

  /**
   * All Ember Uploader methods and functions are defined inside of this namespace.
   *
   * @class EmberUploader
   * @static
   */

  /**
   * @property VERSION
   * @type string
   * @static
   */

  const EmberUploader = Ember.Namespace.create({
    VERSION: _version.default
  });

  if (Ember.libraries) {
    Ember.libraries.registerCoreLibrary('Ember Uploader', EmberUploader.VERSION);
  }

  exports.default = EmberUploader;
});