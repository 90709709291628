define("ember-drag-drop-polyfill/initializers/ember-drag-drop-polyfill", ["exports", "ember-get-config", "mobile-drag-drop", "mobile-drag-drop/scroll-behaviour"], function (_exports, _emberGetConfig, _mobileDragDrop, _scrollBehaviour) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function
    /*application*/
  initialize() {
    const options = _emberGetConfig.default['ember-drag-drop-polyfill'] || {};
    const customOptions = options.customOptions || {};
    const mobileDragDropOptions = options.mobileDragDrop || {};

    if (typeof document === 'undefined' || typeof window === 'undefined') {
      return;
    } // see https://github.com/timruffles/mobile-drag-drop/issues/77


    if (customOptions.enableIOSHack) {
      let passiveSupported = false;

      try {
        const opts = {
          // This function will be called when the browser attempts to access the passive property.
          get passive() {
            passiveSupported = true;
            return false;
          }

        };
        window.addEventListener('test', null, opts);
        window.removeEventListener('test', null, opts);
      } catch (err) {
        passiveSupported = false;
      }

      if (passiveSupported) {
        window.addEventListener('touchmove', function () {}, {
          passive: false
        });
      } else {
        window.addEventListener('touchmove', function () {});
      }
    }

    if (customOptions.includeScrollBehavior) {
      mobileDragDropOptions.dragImageTranslateOverride = _scrollBehaviour.scrollBehaviourDragImageTranslateOverride;
    }

    (0, _mobileDragDrop.polyfill)(mobileDragDropOptions);
  }

  var _default = {
    name: 'ember-drag-drop-polyfill',
    initialize
  };
  _exports.default = _default;
});