define("ember-modal-dialog/templates/components/modal-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "r9m57cPM",
    "block": "[[[8,[30,0,[\"whichModalDialogComponent\"]],null,[[\"@wrapperClass\",\"@wrapperClassNames\",\"@overlayClass\",\"@overlayClassNames\",\"@containerClass\",\"@containerClassNames\",\"@hasOverlay\",\"@translucentOverlay\",\"@clickOutsideToClose\",\"@destinationElementId\",\"@overlayPosition\",\"@tetherTarget\",\"@legacyTarget\",\"@attachment\",\"@targetAttachment\",\"@targetModifier\",\"@targetOffset\",\"@offset\",\"@tetherClassPrefix\",\"@constraints\",\"@attachmentClass\",\"@stack\",\"@value\",\"@onClickOverlay\",\"@onClose\"],[[30,0,[\"wrapperClass\"]],[30,0,[\"wrapperClassNames\"]],[30,0,[\"overlayClass\"]],[30,0,[\"overlayClassNames\"]],[30,0,[\"containerClass\"]],[30,0,[\"containerClassNames\"]],[30,0,[\"hasOverlay\"]],[30,0,[\"translucentOverlay\"]],[30,0,[\"clickOutsideToClose\"]],[30,0,[\"destinationElementId\"]],[30,0,[\"overlayPosition\"]],[30,0,[\"tetherTarget\"]],[30,0,[\"target\"]],[30,0,[\"attachment\"]],[30,0,[\"targetAttachment\"]],[30,0,[\"targetModifier\"]],[30,0,[\"targetOffset\"]],[30,0,[\"offset\"]],[30,0,[\"tetherClassPrefix\"]],[30,0,[\"constraints\"]],[30,0,[\"attachmentClass\"]],[30,0,[\"stack\"]],[30,0,[\"value\"]],[30,0,[\"onClickOverlayAction\"]],[30,0,[\"onCloseAction\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,1,null],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-modal-dialog/templates/components/modal-dialog.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});