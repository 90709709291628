// To generate this data in batch:
// - Change `tests` to `true` in `ember-cli-build.js`
// - Restart ember server
// - Make the deprecation message show up in the console
//   (To generate these, a series of random navigations were done, trying to
//   trigger as many as possible)
// - Run `deprecationWorkflow.flushDeprecations()` in the console, and use the
//   output to update this file.
//
// Otherwise, to add a single one, just add a line with the deprecation id that
// shows up on the end of any Ember deprecation message in the console.

self.deprecationWorkflow = self.deprecationWorkflow || {};
self.deprecationWorkflow.config = {
  workflow: [
    { handler: "silence", matchId: "application-controller.router-properties" },
    { handler: "silence", matchId: "argument-less-helper-paren-less-invocation" },
    { handler: "silence", matchId: "computed-property.override" },
    { handler: "silence", matchId: "deprecated-run-loop-and-computed-dot-access" },
    { handler: "silence", matchId: "ds.serializer.rest.queryRecord-array-response" },
    { handler: "silence", matchId: "ember-component.send-action" },
    { handler: "silence", matchId: "ember-data:default-serializer" },
    { handler: "silence", matchId: "ember-data:method-calls-on-destroyed-store" },
    { handler: "silence", matchId: "ember-global" },
    { handler: "silence", matchId: "ember-metal.get-with-default" },
    { handler: "silence", matchId: "ember-string.prototype-extensions" },
    { handler: "silence", matchId: "ember-views.curly-components.jquery-element" },
    { handler: "silence", matchId: "ember-views.partial" },
    { handler: "silence", matchId: "ember.built-in-components.import" },
    { handler: "silence", matchId: "ember.built-in-components.legacy-arguments" },
    { handler: "silence", matchId: "ember.built-in-components.reopen" },
    { handler: "silence", matchId: "ember.globals-resolver" },
    { handler: "silence", matchId: "implicit-injections" },
    { handler: "silence", matchId: "manager-capabilities.modifiers-3-13" },
    { handler: "silence", matchId: "routing.transition-methods" },
    { handler: "silence", matchId: "this-property-fallback" }
  ]
};
