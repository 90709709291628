define('ember-uploader/index', ['exports', 'ember-uploader/core', 'ember-uploader/uploaders', 'ember-uploader/components'], function (exports, _core, _uploaders, _components) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
    Ember Uploader
    @module ember-uploader
    @main ember-uploader
  */

  _core.default.Uploader = _uploaders.Uploader;
  _core.default.S3Uploader = _uploaders.S3Uploader;

  _core.default.FileField = _components.FileField;

  Ember.lookup.EmberUploader = _core.default;

  exports.default = _core.default;
});