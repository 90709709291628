define("liquid-fire/templates/components/liquid-spacer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lAXLpSh4",
    "block": "[[[8,[39,0],null,[[\"@didMeasure\"],[[28,[37,1],[[30,0],[30,0,[\"sizeChanged\"]]],null]]],[[\"default\"],[[[[1,\"\\n  \"],[18,1,null],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"liquid-measured\",\"action\",\"yield\"]]",
    "moduleName": "liquid-fire/templates/components/liquid-spacer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});