define("liquid-fire/templates/components/liquid-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "406NCR9R",
    "block": "[[[18,1,[[30,0]]]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "liquid-fire/templates/components/liquid-container.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});