define("ember-set-helper/helpers/set", ["exports", "@ember/component/helper", "@ember/debug", "@ember/object"], function (_exports, _helper, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function set(positional) {
    let [target, path, maybeValue] = positional;
    (false && !(typeof path === 'string' && path.length > 0 || typeof path === 'symbol' || typeof path === 'number') && (0, _debug.assert)('you must pass a path to {{set}}. You can pass a path statically, as in `{{set this "foo"}}`, or with the path dynamically, as in `{{set this this.greetingPath "Hello"}}`', typeof path === 'string' && path.length > 0 || typeof path === 'symbol' || typeof path === 'number'));
    return positional.length === 3 ? () => (0, _object.set)(target, path, maybeValue) : value => (0, _object.set)(target, path, value);
  }

  var _default = (0, _helper.helper)(set);

  _exports.default = _default;
});