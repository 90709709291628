define("liquid-fire/components/illiquid-model", ["exports", "@ember/component", "liquid-fire/templates/components/illiquid-model"], function (_exports, _component, _illiquidModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const IlliquidModel = _component.default.extend({
    layout: _illiquidModel.default,
    tagName: '',

    didReceiveAttrs() {
      this._super();

      if (!this._fixedModel) {
        this.set('_fixedModel', this.model);
      }
    }

  });

  IlliquidModel.reopenClass({
    positionalParams: ['model']
  });
  var _default = IlliquidModel;
  _exports.default = _default;
});